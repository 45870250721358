import img1 from "../../Images/woman sitting in front of closed door.jpg"
import img2 from "../../Images/pexels-taufiq-klinkenborg-384553.jpg"
import img3 from "../../Images/genebrutty.jpg"
import img4 from "../../Images/DSC_0558.jpg"
import img5 from "../../Images/gift box.jpg"
import img6 from "../../Images/kenny-eliason-zFSo6bnZJTw-unsplash.jpg"

// pexels-taufiq-klinkenborg-384553.jpg

export let whatWeDoContent = [
   {
      title: "Veterans Services",
      text: "Veterans have disproportionate rates of mental illness, particularly posttraumatic stress disorder (PTSD).",
      img: img1,
      link: "/veterans",
   },
   {
      title: "Helping The Homeless",
      text: "Homelessness is not a choice.... Help IS! Homelessness is not unique to in town Atlanta communities.",
      img: img2,
      link: "/homeless",
   },
   {
      title: "National Poverty & Homelessness",
      text: "Nationwide, two factors have contributed to the increase in homelessness over the past several years.",
      img: img3,
      link: "/achgiving",
   },
   {
      title: "Medical Mission & Missionary Work",
      text: "This program contributes to over 2,000 families, personal hygiene products, clothes, food, household goods, & more.",
      img: img4,
      link: "/mmwork",
   },
   {
      title: "Annual Community Holiday Giving",
      text: "The Community Holiday Giving Program distributes to over 2,000 families",
      img: img5,
      link: "/achgiving",
   },
   {
      title: "Safe Schools",
      text: "The Safe Schools program helps schools foster a safe environment that is supportive and inclusive of LGBTIQA+ students",
      img: img6,
      link: "/safe-schools",
   },
]
