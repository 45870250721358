import React, { useEffect } from "react"
import "./index.css"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import Video from "../../VideosSrc/Global Health  Wellness Mission Trip.mp4"

const variants = {
   visible: { opacity: 1, y: 0 },
   hidden: { opacity: 0, y: 150 },
}
const VideoComponent = () => {
   const controls = useAnimation()
   const [ref, inView] = useInView()

   const container = {
      initial: { opacity: 0 },
      animate: {
         opacity: 1,
         transition: {
            delayChildren: 0.1,
            staggerChildren: 0.3,
            ease: [0.43, 0.13, 0.23, 0.96],
         },
      },
   }
   useEffect(() => {
      if (inView) {
         controls.start("visible")
      }
   }, [controls, inView])



   return (
      <section className="video">
         <h2>Who we Are</h2>
         <motion.div
            className="container3"
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={variants}
            whileInView="animate"
            viewport={{ once: true }}
         >
            <video controls className="videoclass">
               <source src={Video} type="video/mp4"/>
            </video>
         </motion.div>
      </section>
   )
}

export default VideoComponent
