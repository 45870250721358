import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { HashRouter } from "react-router-dom"
import { ScrollToTop } from "react-router-scroll-to-top"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
   <React.StrictMode>
      <HashRouter>
         <ScrollToTop />
         <App />
      </HashRouter>
   </React.StrictMode>
)

reportWebVitals()
